import FormData from "form-data";
import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";

// config

export const api = axios.create({
  // baseURL: process.env.BASE_URL,
  baseURL: process.env.REACT_APP_BASE_API_ENDPOINT,
});

console.log(process.env.REACT_APP_BASE_API_ENDPOINT);
