import { Box, Container, Typography } from "@mui/material";
import React from "react";

function Footer() {
  return (
    <Box
      sx={{
        backgroundColor: "#333",
        minHeight: "6rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: "#fff" }} align="center">
            جميع الحقوق محفوظة &copy; {new Date().getFullYear()} إدارتي Tac Tic
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
