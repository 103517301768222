import FormData from "form-data";
import Cookies from "js-cookie";
import React, { createContext, useContext, useState } from "react";

import { apiLogin, apiLogout } from "./auth/ApiAuth";
import {
  apiReleveCarriereGetAll,
  apiReleveCarriereGetOne,
  apiIncidentCreate,
  apiIncidentDelete,
  apiIncidentUpdate,
} from "./portail/cnss/ApiCnss";

export const TndevContext = createContext(null);

export const TndevCtx = () => {
  return useContext(TndevContext);
};

// authMethods  zone  --> context/auth/ApiAuth

const authMethods = {
  apiLogin,
  apiLogout,
};

// aneti api  zone  --> context/aneti/AnetiSteg

const anetiApiMethods = {
  apiReleveCarriereGetAll,
};

// cnam api  zone  --> context/cnam/ApiCnam

const cnamApiMethods = {
  apiReleveCarriereGetAll,
};
// cnrps api  zone  --> context/cnrps/ApiCnrps

const cnrpsApiMethods = {
  apiReleveCarriereGetAll,
};
// cnss api  zone  --> context/cnss/ApiCnss

const cnssApiMethods = {
  apiReleveCarriereGetAll,
  apiReleveCarriereGetOne,
};

// ministere-finance api  zone  --> context/ministere-finance/ApiMinestereFinance

const minestereFinanceApiMethods = {
  apiReleveCarriereGetAll,
};

// sonede api  zone  --> context/sonede/Apisonede

const sonedeFinanceApiMethods = {
  apiReleveCarriereGetAll,
};

// steg api  zone  --> context/steg/ApiSteg

const stegApiMethods = {
  apiReleveCarriereGetAll,
};
// tunisie telecom api  zone  --> context/tunisieTelecom/ApiTunisieTelecom

const telecomApiMethods = {
  apiReleveCarriereGetAll,
};

export const TndevProvider = ({ children }) => {
  // global states auth
  const [loguedIn, setLoguedIn] = useState(
    Cookies.get("tokenMSA") ? true : false
  );
  const [user, setUser] = useState(
    Cookies.get("userMSA")
      ? JSON.parse(Cookies.get("userMSA"))
      : { fname: "Foulen", lname: "Ben Foulen" }
  );

  const [auth, setAuth] = useState(false);
  const [role, setRole] = useState([2001]);
  //console.log(loguedIn);
  //console.log(user);

  // global states utils

  const [lang, setLang] = useState("ar");
  const [loading, setLoading] = useState(false);

  // global states STEG
  const [bigData, setBigData] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);

  // global states CNSS

  const states = {
    openDrawer,
    setOpenDrawer,
    loguedIn,
    setLoguedIn,
    user,
    setUser,
    bigData,
    setBigData,
    auth,
    setAuth,
    role,
    lang,
    setLang,
    loading,
    setLoading,
  };

  const methods = {
    authMethods,
    cnssApiMethods,
  };

  return (
    <TndevContext.Provider value={[methods, states]}>
      {children}
    </TndevContext.Provider>
  );
};
