import React from "react";
import Navbar from "../../components/Navbar";
function Account() {
  return (
    <>
      <Navbar />
      <div style={{ marginTop: "5rem" }}>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita
        sapiente, nam perferendis optio cumque adipisci atque at incidunt
        aperiam, voluptatum aliquam. Tempora aut optio at? Fuga optio nulla
        ullam ipsa.
      </div>
      <h1>Account index</h1>
    </>
  );
}

export default Account;
