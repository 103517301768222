// react
import * as React from "react";
import { motion } from "framer-motion";
import { TndevCtx } from "../../contexts/TndevContext";
import Cookies from "js-cookie";
import {
  Link,
  Outlet,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import { useMutation } from "react-query";

// mui

import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Divider from "@mui/material/Divider";

import TreeView from "@mui/lab/TreeView";

import TreeItem from "@mui/lab/TreeItem";
import Paper from "@mui/material/Paper";

import { styled } from "@mui/material/styles";
import { Button, Container, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

// ch component

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Faq1() {
  const [methods, states] = TndevCtx();
  const { authMethods } = methods;
  const { apiLogin } = authMethods;
  const { setLoguedIn, user, setUser, lang } = states;

  let navigate = useNavigate();
  let location = useLocation();

  const {
    mutate: login,
    isError,
    isLoading,
    isSuccess,
    data,
  } = useMutation((values) => apiLogin(values), {
    onSuccess: (data) => {
      const { access_token, user } = data;
      if (access_token) {
        setLoguedIn(true);
        setUser(user);
        Cookies.set("user", JSON.stringify(user));
        Cookies.set("token3s", access_token);
        navigate(`/calendrier`);
      }
    },
    onError: (error) => console.log(error),
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let cred = {
      email: data.get("email"),
      password: data.get("password"),
    };

    login(cred);
  };

  const [valueTab, setValueTab] = React.useState("operation");

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };
  const handleChangeOperation = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleNavigate = (event, newRoute) => {
    alert(newRoute);
    navigate(newRoute);
  };
  return (
    <>
      {" "}
      <Box>
        <Typography
          component="h2"
          variant="h6"
          color="primary"
          gutterBottom
          align={lang === "ar" ? "left" : "right"}
        >
          {/* FAQ service 1 */}
        </Typography>

        <Box sx={{ width: "100%" }}>
          <Tabs
            value={valueTab}
            onChange={handleChangeTab}
            textColor="primary"
            indicatorColor="primary"
            aria-label="primary tabs example"
          >
            <Tab value="operation" label="التعليمات" />
            {/* <Tab value="descriptif" label="Descriptif" /> */}
            {/* <Tab value="documentsNecessaires" label="Documents necessaires" /> */}
          </Tabs>
          <Divider />

          {valueTab === "operation" && (
            <Box sx={{ flexGrow: 1, mt: 4 }} component="form">
              <Box
                sx={{ pr: lang === "ar" ? 9 : 3, pl: lang === "ar" ? 3 : 9 }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Button variant="contained" sx={{ bgcolor: "gray" }}>
                      بحث <SearchIcon />
                    </Button>{" "}
                    <TextField
                      autoFocus
                      margin="normal"
                      required
                      sx={{ width: "16rem" }}
                      id="standard-basic"
                      label=""
                      // variant="standard"
                      variant="filled"
                    />
                  </Grid>

                  <Grid item sx={12}>
                    <div>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>
                            {" "}
                            الشركة التونسية للكهرباء والغاز{" "}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse malesuada lacus ex, sit amet
                            blandit leo lobortis eget.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography>
                            {" "}
                            الشركة الوطنية لاستغلال و توزيع المياه
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse malesuada lacus ex, sit amet
                            blandit leo lobortis eget.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                        >
                          <Typography> صندوق الصمان الاجتماعي </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse malesuada lacus ex, sit amet
                            blandit leo lobortis eget.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
          {valueTab === "descriptif" && (
            <Box sx={{ textAlign: "left", p: 5 }}>
              <p>
                {" "}
                طباعة البريد الإلكتروني Lorem ipsum للمصممين نص لوريم ايبسوم
                عربي المجموعة: مقالات دعاية الزيارات: 218700 Lorem ipsum
                للمصممين نص لوريم ايبسوم بالعربي عربي انجليزي ما هو لوريم ايبسوم
                Lorem ipsum لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض
                على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم
                مطبوعه ... بروشور او فلاير على سبيل المثال ... او نماذج مواقع
                انترنت ... وعند موافقه العميل المبدئيه على التصميم يتم ازالة هذا
                النص من التصميم ويتم وضع النصوص النهائية المطلوبة للتصميم ويقول
                البعض ان وضع النصوص التجريبية بالتصميم قد تشغل المشاهد عن وضع
                الكثير من الملاحظات او الانتقادات للتصميم الاساسي. وخلافاَ
                للاعتقاد السائد فإن لوريم إيبسوم ليس نصاَ عشوائياً، بل إن له
                جذور في الأدب اللاتيني الكلاسيكي منذ العام 45 قبل الميلاد. من
                كتاب "حول أقاصي الخير والشر"
              </p>
            </Box>
          )}
          {valueTab === "documentsNecessaires" && (
            <Box sx={{ textAlign: "left", p: 5 }}>
              {" "}
              <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{
                  height: 240,
                  flexGrow: 1,
                  maxWidth: 400,
                  overflowY: "auto",
                }}
              >
                <TreeItem nodeId="1" label="STEG">
                  <TreeItem
                    nodeId="32"
                    label="Calendar"
                    icon={<LocalPrintshopIcon />}
                  />{" "}
                </TreeItem>
                <TreeItem nodeId="2" label="STEG-2">
                  <TreeItem
                    nodeId="33"
                    label="myCalendar"
                    icon={<LocalPrintshopIcon />}
                  />{" "}
                </TreeItem>
              </TreeView>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export default Faq1;
