import React from "react";
import Cookies from "js-cookie";
import { api } from "../ConfigApi";

api.interceptors.request.use(function (config) {
  config.headers = { "X-Requested-With": "XMLHttpRequest" };
  config.headers = { Accept: "application/json" };
  config.headers = { "content-type": "application/json" };

  const token = Cookies.get("token3s") ? Cookies.get("token3s") : null;
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

// authMethods  zone  --> loginController
export const apiLogin = async (cred) => {
  let url = "/login";
  const { data } = await api.post(url, cred);
  return data;
};

// authMethods  zone  --> loginController
export const apiLogout = async () => {
  let url = "/logout";
  const { data } = await api.get(url);
  return data;
};

function ApiAuth() {
  return <div>ApiAuth</div>;
}

export default ApiAuth;
