// react
import * as React from "react";
import { motion } from "framer-motion";
import { TndevCtx } from "../../../contexts/TndevContext";
import Cookies from "js-cookie";
import {
  Link,
  Outlet,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";

// mui

import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Divider from "@mui/material/Divider";

import TreeView from "@mui/lab/TreeView";

import TreeItem from "@mui/lab/TreeItem";
import Paper from "@mui/material/Paper";

import { styled } from "@mui/material/styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
// ch component

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Cnrps1() {
  const queryClient = useQueryClient();
  const [methods, states] = TndevCtx();
  const { cnssApiMethods, authMethods } = methods;
  const { apiLogin } = authMethods;
  const { apiReleveCarriereGetAll } = cnssApiMethods;
  const { bigData, setBigData, setLoguedIn, user, setUser, lang } = states;

  let navigate = useNavigate();
  let location = useLocation();

  // react query lives here

  const {
    isSuccess: releveSuccess,
    isLoading: releveLoading,
    refetch,
    error: releveError,
    data: releveData,
    isFetching,
  } = useQuery(["releve-carriere"], () => apiReleveCarriereGetAll(), {
    onSuccess: (data) => {
      setBigData(data);
    },
    onError: (error) => console.log(error),
  });

  const {
    mutate: login,
    isError,
    isLoading,
    isSuccess,
    data,
  } = useMutation((values) => apiLogin(values), {
    onSuccess: (data) => {
      const { access_token, user } = data;
      if (access_token) {
        setLoguedIn(true);
        setUser(user);
        Cookies.set("user", JSON.stringify(user));
        Cookies.set("token3s", access_token);
        navigate(`/calendrier`);
      }
    },
    onError: (error) => console.log(error),
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let cred = {
      email: data.get("email"),
      password: data.get("password"),
    };

    login(cred);
  };

  const [valueTab, setValueTab] = React.useState("operation");

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };
  const handleChangeOperation = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleNavigate = (event, newRoute) => {
    alert(newRoute);
    navigate(newRoute);
  };

  return (
    <>
      <Box>
        <Typography
          component="h2"
          variant="h6"
          color="primary"
          gutterBottom
          align={lang === "ar" ? "left" : "right"}
        >
          Releve carriere
        </Typography>

        <Box sx={{ width: "100%" }}>
          <Tabs
            value={valueTab}
            onChange={handleChangeTab}
            textColor="primary"
            indicatorColor="primary"
            aria-label="primary tabs example"
          >
            <Tab value="operation" label="Operation" />
            <Tab value="descriptif" label="Descriptif" />
            <Tab value="documentsNecessaires" label="Documents necessaires" />
          </Tabs>
          <Divider />

          <Box
            sx={{ float: "left", my: 5 }}
            className="fomulaire de recherche par matricule "
          >
            <TextField label="t" />
          </Box>

          {valueTab === "operation" && (
            <Box sx={{ flexGrow: 1, mt: 4 }} component="form">
              <Box
                sx={{ pr: lang === "ar" ? 9 : 3, pl: lang === "ar" ? 3 : 9 }}
              >
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="right">Action</StyledTableCell>
                        <StyledTableCell align="right">
                          Trimestre
                        </StyledTableCell>
                        <StyledTableCell align="right">Annee</StyledTableCell>
                        <StyledTableCell align="right">Salaire</StyledTableCell>
                        <StyledTableCell align="right">
                          Matricule
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {releveData?.map((item) => (
                        <StyledTableRow key={item.rownum}>
                          <StyledTableCell align="right">
                            <VisibilityIcon />
                          </StyledTableCell>
                          <StyledTableCell align="right" className="trimestre">
                            {item.trimestre}
                          </StyledTableCell>
                          <StyledTableCell align="right" className="annee">
                            {item.annee}
                          </StyledTableCell>
                          <StyledTableCell align="right" className="salaire">
                            {item.salaire}
                          </StyledTableCell>
                          <StyledTableCell align="right" className="matricule">
                            {item.matricule}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          )}
          {valueTab === "descriptif" && (
            <Box sx={{ textAlign: "left", p: 5 }}>
              <p>
                {" "}
                طباعة البريد الإلكتروني Lorem ipsum للمصممين نص لوريم ايبسوم
                عربي المجموعة: مقالات دعاية الزيارات: 218700 Lorem ipsum
                للمصممين نص لوريم ايبسوم بالعربي عربي انجليزي ما هو لوريم ايبسوم
                Lorem ipsum لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض
                على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم
                مطبوعه ... بروشور او فلاير على سبيل المثال ... او نماذج مواقع
                انترنت ... وعند موافقه العميل المبدئيه على التصميم يتم ازالة هذا
                النص من التصميم ويتم وضع النصوص النهائية المطلوبة للتصميم ويقول
                البعض ان وضع النصوص التجريبية بالتصميم قد تشغل المشاهد عن وضع
                الكثير من الملاحظات او الانتقادات للتصميم الاساسي. وخلافاَ
                للاعتقاد السائد فإن لوريم إيبسوم ليس نصاَ عشوائياً، بل إن له
                جذور في الأدب اللاتيني الكلاسيكي منذ العام 45 قبل الميلاد. من
                كتاب "حول أقاصي الخير والشر"
              </p>
            </Box>
          )}
          {valueTab === "documentsNecessaires" && (
            <Box sx={{ textAlign: "left", p: 5 }}>
              {" "}
              <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{
                  height: 240,
                  flexGrow: 1,
                  maxWidth: 400,
                  overflowY: "auto",
                }}
              >
                <TreeItem nodeId="1" label="STEG">
                  <TreeItem
                    nodeId="32"
                    label="Calendar"
                    icon={<LocalPrintshopIcon />}
                  />{" "}
                </TreeItem>
                <TreeItem nodeId="2" label="STEG-2">
                  <TreeItem
                    nodeId="33"
                    label="myCalendar"
                    icon={<LocalPrintshopIcon />}
                  />{" "}
                </TreeItem>
              </TreeView>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export default Cnrps1;
