import { Link } from "@mui/material";
import React from "react";
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";

function Unauthorized() {
  const location = useLocation();
  return (
    <div>
      <h1>Not authorized to access this area </h1>
      {/* <Navigate to="/" state={{ from: location }} replace /> */}
      <Link color="inherit" href="/">
        Home
      </Link>{" "}
    </div>
  );
}

export default Unauthorized;
