import React from "react";
import Cookies from "js-cookie";
import { api } from "../../ConfigApi";

api.interceptors.request.use(function (config) {
  config.headers = { "X-Requested-With": "XMLHttpRequest" };
  config.headers = { Accept: "application/json" };
  config.headers = { "content-type": "application/json" };

  const token = Cookies.get("token3s") ? Cookies.get("token3s") : null;
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

// cnss get all releve carriere db-json port 5500
export const apiReleveCarriereGetAll = async () => {
  let url = `/auto-releve`;
  const { data } = await api.get(url);
  return data;
};
// cnss get all releve carriere db-json port 5500
export const apiReleveCarriereGetOne = async (matricule = "1") => {
  let url = `/auto-releve/${matricule}`;
  const { data } = await api.get(url);
  return data;
};

// incidents  zone  --> IncidenController
export const apiIncidentsAll = async () => {
  let url = "/incidents-all";
  const { data } = await api.get(url);
  return data;
};

export const apiIncidentCreate = async (dt) => {
  let url = "/incident-create";
  const { data } = await api.post(url, dt);
  return data;
};

export const apiIncidentDelete = async (id) => {
  let payload = {
    params: {
      id: id,
    },
  };
  let url = "/incident-delete";
  const { data } = await api.delete(url, payload);
  return data;
};

export const apiIncidentUpdate = async (dt) => {
  let payload = {
    id: dt.id,
    data: dt.data,
  };
  console.log(payload);

  let url = "/incident-update";
  const { data } = await api.put(url, payload);
  return data;
};

function ApiCnss() {
  return <div>ApiCnss</div>;
}

export default ApiCnss;
