import React from "react";
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import { TndevCtx } from "../contexts/TndevContext";

const RequireAuth = ({ allowedRoles }) => {
  const [methods, states] = TndevCtx();
  const { authMethods } = methods;
  const { apiLogin } = authMethods;
  const { loguedIn, setLoguedIn, user, setUser, auth, setAuth, role } = states;

  const location = useLocation();

  return auth ? (
    role.find((role) => allowedRoles.includes(role)) ? (
      <Outlet />
    ) : (
      <Navigate to="/unauthorized" state={{ from: location }} replace />
    )
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default RequireAuth;
