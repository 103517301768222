// react

import * as React from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useMutation } from "react-query";
import { motion } from "framer-motion";
import { TndevCtx } from "../../contexts/TndevContext";
import { useFormik } from "formik";
import * as Yup from "yup";

// mui
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/system";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import { Paper } from "@mui/material";
// i18n
import { FormattedMessage, useIntl } from "react-intl";

// img
import hidra from "../../assets/logo/hidra.png";
import idarti from "../../assets/logo/idarti.png";
import presidentiel from "../../assets/logo/presidentiel.png";

const MyTextField = styled(TextField)({
  "& label": {
    transformOrigin: "right !important",
    left: "inherit !important",
    right: "1.75rem !important",
    fontSize: "small",
    color: "#807D7B",
    fontWeight: 400,
    overflow: "unset",
  },
});

export default function Login() {
  // react context

  const [methods, states] = TndevCtx();
  const { authMethods } = methods;
  const { apiLogin } = authMethods;

  const {
    loguedIn,
    setLoguedIn,
    user,
    setUser,
    auth,
    setAuth,
    role,
    lang,
    setLang,
    loading,
    setLoading,
  } = states;

  // router dom hooks
  const navigate = useNavigate();
  const location = useLocation();
  // middleware kind to catch the user intent to go
  const from = location.state?.from?.pathname || "/";

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid Email address ").required("required"),

      password: Yup.string()
        .max(35, "Must be 35 characters or less")
        .required("required"),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
    onReset: (values) => {
      values = {
        email: "",
        password: "",
      };
    },
  });

  // react query

  const {
    mutate: login,
    isError,
    isLoading,
    isSuccess,
    data,
  } = useMutation((values) => apiLogin(values), {
    onSuccess: (data) => {
      const { access_token, user } = data;
      if (access_token) {
        setLoguedIn(true);
        setUser(user);
        Cookies.set("userMSA", JSON.stringify(user));
        Cookies.set("tokenMSA", access_token);
        navigate(`portail`);
      }
    },
    onError: (error) => console.log(error),
  });

  // submit handler
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let cred = {
      email: data.get("email"),
      password: data.get("password"),
    };
    setLang("ar");
    //;
    setLoading(true);
    //login(cred);
    const user = {
      fname: "Foulen",
      lname: "Ben Foulen",
    };
    const access_token = "123456789";
    if (Cookies.get("userMSA")) {
      Cookies.set("userMSA", JSON.stringify(user));
      Cookies.set("tokenMSA", access_token);
    } else {
      Cookies.set("userMSA", JSON.stringify(user));
      Cookies.set("tokenMSA", access_token);
    }

    setTimeout(() => {
      setAuth(true);
      navigate("home");
      setLoading(false);
    }, 700);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper
        elevation={5}
        sx={{
          marginTop: 18,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: 3,
        }}
      >
        <span
          style={{
            textAlign: "center",
            display: "block",
            color: "red",
            fontSize: "1.3rem",
          }}
        >
          {data && data?.response_code === 403 ? data?.error : ""}
        </span>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            sx={{
              background: "transparent",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
              src={presidentiel}
              alt="presidentiel"
              height="65"
            />
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              background: "transparent",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 3 }}
              src={hidra}
              alt="hidra"
              height="55"
            />
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              background: "transparent",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2 }}
              src={idarti}
              alt="idarti"
              height="45"
            />
          </Grid>
        </Grid>

        <Typography component="h1" variant="h5" sx={{ mt: 2.5 }}>
          تسجيل الدخول
        </Typography>
        <Box
          sx={{
            padding: "1rem",
          }}
        >
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              error={formik.touched.email && !!formik.errors.email}
              helperText={
                formik.touched.email && !!formik.errors.email ? (
                  <FormattedMessage id="required" />
                ) : (
                  ""
                )
              }
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="text"
              margin="normal"
              required
              fullWidth
              id="email"
              // label={<FormattedMessage id="email" />}
              label="اسم المستعمل"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              error={formik.touched.password && !!formik.errors.password}
              helperText={
                formik.touched.password && !!formik.errors.password ? (
                  <FormattedMessage id="required" />
                ) : (
                  ""
                )
              }
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              margin="normal"
              required
              fullWidth
              name="password"
              // label={<FormattedMessage id="password" />}
              label="كلمة العبور"
              type="password"
              id="password"
              autoComplete="current-password"
            />

            <Button
              // disabled={!(formik.isValid && formik.dirty)}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{
                mt: 3,
                mb: 2,
                fontSize: "1.2rem",
              }}
            >
              {loading ? <CircularProgress sx={{ color: "#fff" }} /> : "دخول"}
            </Button>
          </Box>
        </Box>
      </Paper>

      <Typography
        sx={{ mt: 2, mb: 4 }}
        variant="body2"
        color="text.secondary"
        align="center"
      >
        {"©"}
        <Link color="inherit" href="#" sx={{ textDecoration: "none" }}>
          جميع الحقوق محفوظة
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </Container>
  );
}
