import React from "react";
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import { TndevCtx } from "../contexts/TndevContext";

const GuestPath = () => {
  const [methods, states] = TndevCtx();
  const { authMethods } = methods;
  const { apiLogin } = authMethods;
  const { loguedIn, setLoguedIn, user, setUser, auth, setAuth, role } = states;

  const location = useLocation();

  return !auth ? (
    <Outlet />
  ) : (
    <Navigate to="portail" state={{ from: location }} replace />
  );
};

export default GuestPath;
