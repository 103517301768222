// react
import * as React from "react";
import { TndevCtx } from "../../contexts/TndevContext";
import { Link, useNavigate, useLocation, Navigate } from "react-router-dom";
import { useMutation } from "react-query";
import { motion } from "framer-motion";
import Cookies from "js-cookie";

// mui
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CardActions from "@mui/material/CardActions";
import { Card, CardContent } from "@mui/material";

// MSA component
import Navbar from "../../components/Navbar";
import steg from "../../assets/msa/steg.webp";
import aneti from "../../assets/msa/aneti.webp";
import sonede from "../../assets/msa/sonede.webp";
import cnss2 from "../../assets/msa/cnss2.webp";
import finance from "../../assets/msa/finance.webp";
import tt from "../../assets/msa/tt.webp";
import cnss from "../../assets/msa/cnss.webp";
import cnrps from "../../assets/msa/cnrps.webp";
import Footer from "../../components/Footer";

const theme = createTheme();

export default function Portail() {
  const [methods, states] = TndevCtx();
  const { authMethods } = methods;
  const { apiLogin } = authMethods;
  const { setLoguedIn, user, setUser } = states;

  const navigate = useNavigate();
  const location = useLocation();

  const {
    mutate: login,
    isError,
    isLoading,
    isSuccess,
    data,
  } = useMutation((values) => apiLogin(values), {
    onSuccess: (data) => {
      const { access_token, user } = data;
      if (access_token) {
        setLoguedIn(true);
        setUser(user);
        Cookies.set("user", JSON.stringify(user));
        Cookies.set("token3s", access_token);
        navigate(`/calendrier`);
      }
    },
    onError: (error) => console.log(error),
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let cred = {
      email: data.get("email"),
      password: data.get("password"),
    };

    login(cred);
  };

  // steg redirect handler
  const handleStegService = (event) => {
    event.preventDefault();

    //login(cred);
    navigate("steg");
  };
  // cnss redirect handler
  const handleCnssService = (event) => {
    event.preventDefault();

    //login(cred);
    navigate("cnss");
  };

  return (
    <>
      <Navbar />

      <Container
        maxWidth="md"
        sx={{
          bgcolor: "transparent",
          marginTop: "2rem",
          minHeight: "90vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              component={motion.div}
              whileHover={{
                scale: 1.05,
                transition: { duration: 0.1 },
              }}
              whileTap={{ scale: 0.9 }}
              sx={{ maxWidth: "32rem" }}
            >
              <Link to="/steg" style={{ textDecoration: "none" }}>
                <CardContent>
                  {/* <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                    variant="h5"
                    component="div"
                    align="center"
                  >
                    STEG
                  </Typography> */}

                  <img
                    src={steg}
                    alt="Picture of steg"
                    width={150}
                    height={150}
                    style={{
                      display: "block",
                      maxWidth: "100%",
                      width: "100%",
                    }}
                  />
                </CardContent>
              </Link>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              component={motion.div}
              whileHover={{
                scale: 1.05,
                transition: { duration: 0.1 },
              }}
              whileTap={{ scale: 0.9 }}
              sx={{ maxWidth: "32rem" }}
            >
              <Link to="/cnrps" style={{ textDecoration: "none" }}>
                <CardContent>
                  {/* <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                    variant="h5"
                    component="div"
                    align="center"
                  >
                    CNRPS
                  </Typography> */}

                  <img
                    src={cnrps}
                    alt="Picture of cnrps"
                    width={150}
                    height={150}
                    style={{
                      display: "block",
                      maxWidth: "100%",
                      width: "100%",
                    }}
                  />
                </CardContent>
              </Link>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              component={motion.div}
              whileHover={{
                scale: 1.05,
                transition: { duration: 0.1 },
              }}
              whileTap={{ scale: 0.9 }}
              sx={{ maxWidth: "32rem" }}
            >
              <Link to="/ministere-finance" style={{ textDecoration: "none" }}>
                <CardContent>
                  {/* <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                    variant="h5"
                    component="div"
                    align="center"
                  >
                    FINANCE
                  </Typography> */}

                  <img
                    src={finance}
                    alt="Picture of finance"
                    width={150}
                    height={150}
                    style={{
                      display: "block",
                      maxWidth: "100%",
                      width: "100%",
                    }}
                  />
                </CardContent>
              </Link>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              component={motion.div}
              whileHover={{
                scale: 1.05,
                transition: { duration: 0.1 },
              }}
              whileTap={{ scale: 0.9 }}
              sx={{ maxWidth: "32rem" }}
            >
              <Link to="/cnam" style={{ textDecoration: "none" }}>
                <CardContent>
                  {/* <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                    variant="h5"
                    component="div"
                    align="center"
                  >
                    CNAM
                  </Typography> */}

                  <img
                    src={cnss}
                    alt="Picture of cnss"
                    width={150}
                    height={150}
                    style={{
                      display: "block",
                      maxWidth: "100%",
                      width: "100%",
                    }}
                  />
                </CardContent>
              </Link>
            </Card>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              component={motion.div}
              whileHover={{
                scale: 1.05,
                transition: { duration: 0.1 },
              }}
              whileTap={{ scale: 0.9 }}
              sx={{ maxWidth: "32rem" }}
            >
              <Link to="/sonede" style={{ textDecoration: "none" }}>
                <CardContent>
                  {/* <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                    variant="h5"
                    component="div"
                    align="center"
                  >
                    SONEDE
                  </Typography> */}

                  <img
                    src={sonede}
                    alt="Picture of sonede"
                    width={150}
                    height={150}
                    style={{
                      display: "block",
                      maxWidth: "100%",
                      width: "100%",
                    }}
                  />
                </CardContent>
              </Link>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              component={motion.div}
              whileHover={{
                scale: 1.05,
                transition: { duration: 0.1 },
              }}
              whileTap={{ scale: 0.9 }}
              sx={{ maxWidth: "32rem" }}
            >
              <Link to="/aneti" style={{ textDecoration: "none" }}>
                <CardContent>
                  {/* <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                    variant="h5"
                    component="div"
                    align="center"
                  >
                    ANETI
                  </Typography> */}

                  <img
                    src={aneti}
                    alt="Picture of aneti"
                    width={150}
                    height={150}
                    style={{
                      display: "block",
                      maxWidth: "100%",
                      width: "100%",
                    }}
                  />
                </CardContent>
              </Link>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              component={motion.div}
              whileHover={{
                scale: 1.05,
                transition: { duration: 0.1 },
              }}
              whileTap={{ scale: 0.9 }}
              sx={{ maxWidth: "32rem" }}
            >
              <Link to="/cnss" style={{ textDecoration: "none" }}>
                <CardContent>
                  {/* <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                    variant="h5"
                    component="div"
                    align="center"
                  >
                    CNSS
                  </Typography> */}

                  <img
                    src={cnss2}
                    alt="Picture of cnss2"
                    width={150}
                    height={150}
                    style={{
                      display: "block",
                      maxWidth: "100%",
                      width: "100%",
                    }}
                  />
                </CardContent>
              </Link>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              component={motion.div}
              whileHover={{
                scale: 1.05,
                transition: { duration: 0.1 },
              }}
              whileTap={{ scale: 0.9 }}
              sx={{ maxWidth: "32rem" }}
            >
              <Link to="/telecom" style={{ textDecoration: "none" }}>
                <CardContent>
                  {/* <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                    variant="h5"
                    component="div"
                    align="center"
                  >
                    TUNISIE TELECOM
                  </Typography> */}

                  <img
                    src={tt}
                    alt="Picture of telecom"
                    width={150}
                    height={150}
                    style={{
                      display: "block",
                      maxWidth: "100%",
                      width: "100%",
                    }}
                  />
                </CardContent>
              </Link>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Box
        sx={{
          backgroundColor: "#333",
          minHeight: "6rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          position: "Static",
        }}
      >
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: "#fff", align: "center" }}>
            جميع الحقوق محفوظة &copy; {new Date().getFullYear()} إدارتي Tac Tic
          </Typography>
        </Container>
      </Box>
    </>
  );
}
