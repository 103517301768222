// react
import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter, Routes, Route } from "react-router-dom";

// context react query
import { TndevProvider } from "./contexts/TndevContext";
import { QueryClient, QueryClientProvider } from "react-query";

import CssBaseline from "@mui/material/CssBaseline";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AnimatePresence } from "framer-motion";

const queryClient = new QueryClient();

const container = ReactDOM.createRoot(document.getElementById("root"));

container.render(
  <AnimatePresence exitBeforeEnter>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <TndevProvider>
          <CssBaseline />
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </TndevProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </AnimatePresence>
);

reportWebVitals();
