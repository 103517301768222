// global css
import "./App.css";
// react
import * as React from "react";
import { TndevCtx } from "./contexts/TndevContext";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { IntlProvider } from "react-intl";
import { AnimatePresence } from "framer-motion";

// mui
import Paperbase from "./components/services/Paperbase";
import { CenterFocusStrong } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import theme from "./Theme";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// i18n

import { locales } from "./i18n/locales";
import arabic from "./i18n/languages/ar.json";
import english from "./i18n/languages/en.json";
import french from "./i18n/languages/fr.json";

// ch component
import Layout from "./components/Layout";
import Login from "./pages/auth/Login";

// general components
import Missing from "./components/Missing";
import RequireAuth from "./components/RequireAuth";
import Unauthorized from "./components/Unauthorized";
import GuestPath from "./components/GuestPath";
import Calendrier from "./pages/calendrier/Calendrier";

// Home  page
import Home from "./pages/home/Home";

// Administration  page
import Administration from "./pages/admistration/index";
import Admin1 from "./pages/admistration/Admin1";
import Admin2 from "./pages/admistration/Admin2";
import Admin3 from "./pages/admistration/Admin3";
import Admin4 from "./pages/admistration/Admin4";
import Admin5 from "./pages/admistration/Admin5";
import Admin6 from "./pages/admistration/Admin6";
import Admin7 from "./pages/admistration/Admin7";
import Admin8 from "./pages/admistration/Admin8";
// discussion  pages
import Discussions from "./pages/discussion/index";
import Discuss1 from "./pages/discussion/Discuss1";
import Discuss2 from "./pages/discussion/Discuss2";
import Discuss3 from "./pages/discussion/Discuss3";
import Discuss4 from "./pages/discussion/Discuss4";
import Discuss5 from "./pages/discussion/Discuss5";
import Discuss6 from "./pages/discussion/Discuss6";
import Discuss7 from "./pages/discussion/Discuss7";
import Discuss8 from "./pages/discussion/Discuss8";
// statistic  components
import Statistics from "./pages/statistics/index";
import Stat1 from "./pages/statistics/Stat1";
import Stat2 from "./pages/statistics/Stat2";
import Stat3 from "./pages/statistics/Stat3";
import Stat4 from "./pages/statistics/Stat4";
import Stat5 from "./pages/statistics/Stat5";
import Stat6 from "./pages/statistics/Stat6";
import Stat7 from "./pages/statistics/Stat7";
import Stat8 from "./pages/statistics/Stat8";

// faq  components
import Faq from "./pages/faq/index";
import Faq1 from "./pages/faq/Faq1";
import Faq2 from "./pages/faq/Faq2";
import Faq3 from "./pages/faq/Faq3";
import Faq4 from "./pages/faq/Faq4";
import Faq5 from "./pages/faq/Faq5";
import Faq6 from "./pages/faq/Faq6";
import Faq7 from "./pages/faq/Faq7";
import Faq8 from "./pages/faq/Faq8";

// portail
import Portail from "./pages/portail/index";

// Aneti components
import Aneti from "./pages/portail/aneti/index";
import Aneti1 from "./pages/portail/aneti/Aneti1";
import Aneti2 from "./pages/portail/aneti/Aneti2";
import Aneti3 from "./pages/portail/aneti/Aneti3";
import Aneti4 from "./pages/portail/aneti/Aneti4";
import Aneti5 from "./pages/portail/aneti/Aneti5";
import Aneti6 from "./pages/portail/aneti/Aneti6";
import Aneti7 from "./pages/portail/aneti/Aneti7";
import Aneti8 from "./pages/portail/aneti/Aneti8";

// Cnam components
import Cnam from "./pages/portail/cnam/index";
import Cnam1 from "./pages/portail/cnam/Cnam1";
import Cnam2 from "./pages/portail/cnam/Cnam2";
import Cnam3 from "./pages/portail/cnam/Cnam3";
import Cnam4 from "./pages/portail/cnam/Cnam4";
import Cnam5 from "./pages/portail/cnam/Cnam5";
import Cnam6 from "./pages/portail/cnam/Cnam6";
import Cnam7 from "./pages/portail/cnam/Cnam7";
import Cnam8 from "./pages/portail/cnam/Cnam8";

// CNRPS components
import Cnrps from "./pages/portail/cnrps/index";
import Cnrps1 from "./pages/portail/cnrps/Cnrps1";
import Cnrps2 from "./pages/portail/cnrps/Cnrps2";
import Cnrps3 from "./pages/portail/cnrps/Cnrps3";
import Cnrps4 from "./pages/portail/cnrps/Cnrps4";
import Cnrps5 from "./pages/portail/cnrps/Cnrps5";
import Cnrps6 from "./pages/portail/cnrps/Cnrps6";
import Cnrps7 from "./pages/portail/cnrps/Cnrps7";
import Cnrps8 from "./pages/portail/cnrps/Cnrps8";

// CNSS components
import Cnss from "./pages/portail/cnss/index";
import Cnss1 from "./pages/portail/cnss/ReleveCarriere";
import Cnss2 from "./pages/portail/cnss/Cnss2";
import Cnss3 from "./pages/portail/cnss/Cnss3";
import Cnss4 from "./pages/portail/cnss/Cnss4";
import Cnss5 from "./pages/portail/cnss/Cnss5";
import Cnss6 from "./pages/portail/cnss/Cnss6";
import Cnss7 from "./pages/portail/cnss/Cnss7";
import Cnss8 from "./pages/portail/cnss/Cnss8";

// Minester de finance components
import Mfinance from "./pages/portail/ministere_finance/index";
import Mfinance1 from "./pages/portail/ministere_finance/Mfinance1";
import Mfinance2 from "./pages/portail/ministere_finance/Mfinance2";
import Mfinance3 from "./pages/portail/ministere_finance/Mfinance3";
import Mfinance4 from "./pages/portail/ministere_finance/Mfinance4";
import Mfinance5 from "./pages/portail/ministere_finance/Mfinance5";
import Mfinance6 from "./pages/portail/ministere_finance/Mfinance6";
import Mfinance7 from "./pages/portail/ministere_finance/Mfinance7";
import Mfinance8 from "./pages/portail/ministere_finance/Mfinance8";

// Sonede components
import Sonede from "./pages/portail/sonede/index";
import Sonede1 from "./pages/portail/sonede/Sonede1";
import Sonede2 from "./pages/portail/sonede/Sonede2";
import Sonede3 from "./pages/portail/sonede/Sonede3";
import Sonede4 from "./pages/portail/sonede/Sonede4";
import Sonede5 from "./pages/portail/sonede/Sonede5";
import Sonede6 from "./pages/portail/sonede/Sonede6";
import Sonede7 from "./pages/portail/sonede/Sonede7";
import Sonede8 from "./pages/portail/sonede/Sonede8";

// steg components
import Steg from "./pages/portail/steg/index";
import AutoReleveCompteur from "./pages/portail/steg/AutoReleveCompteur";
import ConsultationFacture from "./pages/portail/steg/ConsultationFacture";
import DeplacementCompteur from "./pages/portail/steg/DeplacementCompteur";
import HistoriqueFactures from "./pages/portail/steg/HistoriqueFactures";
import HistoriquePaiements from "./pages/portail/steg/HistoriquePaiements";
import InscriptionServiceSMS from "./pages/portail/steg/InscriptionServiceSMS";
import RelanceRappelAffaire from "./pages/portail/steg/RelanceRappelAffaire";
import SuiviAffaireService from "./pages/portail/steg/SuiviAffaireService";

// Telecom components
import Telecom from "./pages/portail/tunisie_telecom/index";
import Telecom1 from "./pages/portail/tunisie_telecom/Telecom1";
import Telecom2 from "./pages/portail/tunisie_telecom/Telecom2";
import Telecom3 from "./pages/portail/tunisie_telecom/Telecom3";
import Telecom4 from "./pages/portail/tunisie_telecom/Telecom4";
import Telecom5 from "./pages/portail/tunisie_telecom/Telecom5";
import Telecom6 from "./pages/portail/tunisie_telecom/Telecom6";
import Telecom7 from "./pages/portail/tunisie_telecom/Telecom7";
import Telecom8 from "./pages/portail/tunisie_telecom/Telecom8";

// general link
// import Administration from "./pages/admistration/indexold2";
// import Discussion from "./pages/discussion/indexOld";
// import Statistics from "./pages/statistics/indexold";
// import Faq from "./pages/faq/indexold";
import Account from "./pages/auth/Account";

// Create rtl cache
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

function App() {
  const [methods, states] = TndevCtx();
  const { authMethods } = methods;
  const { testContext } = authMethods;

  const { loguedIn, setLoguedIn, user, setUser, auth, setAuth, lang, setLang } =
    states;

  const ROLES = {
    ADMIN: 2001,
    EMPLOYEE: 2002,
  };

  const messages = {
    en: english,
    fr: french,
    ar: arabic,
  };

  // check localstorage here
  //console.log(`${process.env.REACT_APP_BASE_PUBLIC_URL}/`);

  React.useEffect(() => {
    document.dir = lang === "ar" ? "rtl" : "ltr";
  }, []);
  //get locale storage lang
  React.useEffect(() => {
    const lang = JSON.parse(localStorage.getItem("lang"));
    if (lang) {
      setLang(lang);
    }
  }, []);
  //set localestorage
  React.useEffect(() => {
    localStorage.setItem("lang", JSON.stringify(lang));
  }, [lang]);

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <div dir={lang === "ar" ? "rtl" : "ltr"}>
          <IntlProvider
            messages={messages[lang]}
            locale={lang}
            defaultLocale={locales.arabic}
          >
            {/* <Navbarr /> */}
            <Routes path={`/`} element={<Layout />}>
              {/* public routes */}
              <Route element={<GuestPath />}>
                <Route path={`/`} element={<Login />} />
              </Route>
              <Route path={`unauthorized`} element={<Unauthorized />} />
              {/* protected routes */}
              {/* protected routes for admin only */}
              <Route element={<RequireAuth allowedRoles={[ROLES.ADMIN]} />}>
                <Route path={`service`} element={<Paperbase />} />
              </Route>
              {/* end route  admin only/ */}
              {/* protected routes for employee and admin  */}
              <Route
                element={
                  <RequireAuth allowedRoles={[ROLES.ADMIN, ROLES.EMPLOYEE]} />
                }
              >
                <Route path={`account`} element={<Account />} />
                <Route path={`home`} element={<Home />} />
                {/* <Route path={`administration`} element={<Administration />} /> */}
                {/* <Route path={`discussion`} element={<Discussion />} /> */}
                {/* <Route path={`statistics`} element={<Statistics />} /> */}
                {/* <Route path={`faq`} element={<Faq />} /> */}

                {/* discussion begin*/}
                <Route path={`faq/*`} element={<Faq />}>
                  <Route path={`*`} element={<Faq1 />} />
                  <Route path={`faq1`} element={<Faq1 />} />
                  <Route path={`faq2`} element={<Faq2 />} />
                  <Route path={`faq3`} element={<Faq3 />} />
                  <Route path={`faq4`} element={<Faq4 />} />
                  <Route path={`faq5`} element={<Faq5 />} />
                  <Route path={`faq6`} element={<Faq6 />} />
                  <Route path={`faq7`} element={<Faq7 />} />
                  <Route path={`faq8`} element={<Faq8 />} />
                </Route>
                {/* end discussion route */}
                {/* discussion begin*/}
                <Route path={`statistics/*`} element={<Statistics />}>
                  <Route path={`*`} element={<Stat1 />} />
                  <Route path={`stat1`} element={<Stat1 />} />
                  <Route path={`stat2`} element={<Stat2 />} />
                  <Route path={`stat3`} element={<Stat3 />} />
                  <Route path={`stat4`} element={<Stat4 />} />
                  <Route path={`stat5`} element={<Stat5 />} />
                  <Route path={`stat6`} element={<Stat6 />} />
                  <Route path={`stat7`} element={<Stat7 />} />
                  <Route path={`stat8`} element={<Stat8 />} />
                </Route>
                {/* end discussion route */}

                {/* administration begin*/}
                <Route path={`administration/*`} element={<Administration />}>
                  <Route path={`*`} element={<Admin1 />} />
                  <Route path={`admin1`} element={<Admin1 />} />
                  <Route path={`admin2`} element={<Admin2 />} />
                  <Route path={`admin3`} element={<Admin3 />} />
                  <Route path={`admin4`} element={<Admin4 />} />
                  <Route path={`admin5`} element={<Admin5 />} />
                  <Route path={`admin6`} element={<Admin6 />} />
                  <Route path={`admin7`} element={<Admin7 />} />
                  <Route path={`admin8`} element={<Admin8 />} />
                </Route>
                {/* end administration route */}

                {/* discussion begin*/}
                <Route path={`discussion/*`} element={<Discussions />}>
                  <Route path={`*`} element={<Discuss1 />} />
                  <Route path={`discuss1`} element={<Discuss1 />} />
                  <Route path={`discuss2`} element={<Discuss2 />} />
                  <Route path={`discuss3`} element={<Discuss3 />} />
                  <Route path={`discuss4`} element={<Discuss4 />} />
                  <Route path={`discuss5`} element={<Discuss5 />} />
                  <Route path={`discuss6`} element={<Discuss6 />} />
                  <Route path={`discuss7`} element={<Discuss7 />} />
                  <Route path={`discuss8`} element={<Discuss8 />} />
                </Route>
                {/* end discussion route */}

                {/* portail zone */}
                <Route path={`portail/*`} element={<Portail />} />

                {/* aneti begin*/}
                <Route path={`aneti/*`} element={<Aneti />}>
                  <Route path={`*`} element={<Aneti1 />} />
                  <Route path={`aneti1`} element={<Aneti1 />} />
                  <Route path={`aneti2`} element={<Aneti2 />} />
                  <Route path={`aneti3`} element={<Aneti3 />} />
                  <Route path={`aneti4`} element={<Aneti4 />} />
                  <Route path={`aneti5`} element={<Aneti5 />} />
                  <Route path={`aneti6`} element={<Aneti6 />} />
                  <Route path={`aneti7`} element={<Aneti7 />} />
                  <Route path={`aneti8`} element={<Aneti8 />} />
                </Route>
                {/* end aneti route */}
                {/* cnam begin*/}
                <Route path={`cnam/*`} element={<Cnam />}>
                  <Route path={`*`} element={<Cnam1 />} />
                  <Route path={`cnam1`} element={<Cnam1 />} />
                  <Route path={`cnam2`} element={<Cnam2 />} />
                  <Route path={`cnam3`} element={<Cnam3 />} />
                  <Route path={`cnam4`} element={<Cnam4 />} />
                  <Route path={`cnam5`} element={<Cnam5 />} />
                  <Route path={`cnam6`} element={<Cnam6 />} />
                  <Route path={`cnam7`} element={<Cnam7 />} />
                  <Route path={`cnam8`} element={<Cnam8 />} />
                </Route>
                {/* end cnam route */}
                {/* cnrps begin*/}
                <Route path={`cnrps/*`} element={<Cnrps />}>
                  <Route path={`*`} element={<Cnrps1 />} />
                  <Route path={`cnrps1`} element={<Cnrps1 />} />
                  <Route path={`cnrps2`} element={<Cnrps2 />} />
                  <Route path={`cnrps3`} element={<Cnrps3 />} />
                  <Route path={`cnrps4`} element={<Cnrps4 />} />
                  <Route path={`cnrps5`} element={<Cnrps5 />} />
                  <Route path={`cnrps6`} element={<Cnrps6 />} />
                  <Route path={`cnrps7`} element={<Cnrps7 />} />
                  <Route path={`cnrps8`} element={<Cnrps8 />} />
                </Route>
                {/* end cnrps route */}

                {/* cnss begin*/}
                <Route path={`cnss/*`} element={<Cnss />}>
                  <Route path={`*`} element={<Cnss1 />} />
                  <Route path={`cnss1`} element={<Cnss1 />} />
                  <Route path={`cnss2`} element={<Cnss2 />} />
                  <Route path={`cnss3`} element={<Cnss3 />} />
                  <Route path={`cnss4`} element={<Cnss4 />} />
                  <Route path={`cnss5`} element={<Cnss5 />} />
                  <Route path={`cnss6`} element={<Cnss6 />} />
                  <Route path={`cnss7`} element={<Cnss7 />} />
                  <Route path={`cnss8`} element={<Cnss8 />} />
                </Route>
                {/* end cnss route */}
                {/* ministere-finance begin*/}
                <Route path={`ministere-finance/*`} element={<Mfinance />}>
                  <Route path={`*`} element={<Mfinance1 />} />
                  <Route path={`mfinance1`} element={<Mfinance1 />} />
                  <Route path={`mfinance2`} element={<Mfinance2 />} />
                  <Route path={`mfinance3`} element={<Mfinance3 />} />
                  <Route path={`mfinance4`} element={<Mfinance4 />} />
                  <Route path={`mfinance5`} element={<Mfinance5 />} />
                  <Route path={`mfinance6`} element={<Mfinance6 />} />
                  <Route path={`mfinance7`} element={<Mfinance7 />} />
                  <Route path={`mfinance8`} element={<Mfinance8 />} />
                </Route>
                {/* end ministere-finance route */}
                {/* sonede begin*/}
                <Route path={`sonede/*`} element={<Sonede />}>
                  <Route path={`*`} element={<Sonede1 />} />
                  <Route path={`sonede1`} element={<Sonede1 />} />
                  <Route path={`sonede2`} element={<Sonede2 />} />
                  <Route path={`sonede3`} element={<Sonede3 />} />
                  <Route path={`sonede4`} element={<Sonede4 />} />
                  <Route path={`sonede5`} element={<Sonede5 />} />
                  <Route path={`sonede6`} element={<Sonede6 />} />
                  <Route path={`sonede7`} element={<Sonede7 />} />
                  <Route path={`sonede8`} element={<Sonede8 />} />
                </Route>
                {/* end sonede route */}
                {/* steg begin*/}
                <Route path={`steg/*`} element={<Steg />}>
                  <Route path={`*`} element={<DeplacementCompteur />} />
                  <Route
                    path={`releve-compteur`}
                    element={<AutoReleveCompteur />}
                  />
                  <Route
                    path={`consultation-facture`}
                    element={<ConsultationFacture />}
                  />
                  <Route
                    path={`deplacement-compteur`}
                    element={<DeplacementCompteur />}
                  />
                  <Route
                    path={`historique-factures`}
                    element={<HistoriqueFactures />}
                  />
                  <Route
                    path={`historique-paiements`}
                    element={<HistoriquePaiements />}
                  />
                  <Route
                    path={`inscription-sms`}
                    element={<InscriptionServiceSMS />}
                  />
                  <Route
                    path={`relance-affaire`}
                    element={<RelanceRappelAffaire />}
                  />
                  <Route
                    path={`suivi-affaire`}
                    element={<SuiviAffaireService />}
                  />
                </Route>
                {/* end route steg */}

                {/* telecom begin*/}
                <Route path={`telecom/*`} element={<Telecom />}>
                  <Route path={`*`} element={<Telecom1 />} />
                  <Route path={`telecom1`} element={<Telecom1 />} />
                  <Route path={`telecom2`} element={<Telecom2 />} />
                  <Route path={`telecom3`} element={<Telecom3 />} />
                  <Route path={`telecom4`} element={<Telecom4 />} />
                  <Route path={`telecom5`} element={<Telecom5 />} />
                  <Route path={`telecom6`} element={<Telecom6 />} />
                  <Route path={`telecom7`} element={<Telecom7 />} />
                  <Route path={`telecom8`} element={<Telecom8 />} />
                </Route>
                {/* end telecom route */}
              </Route>{" "}
              {/* end route for employee and admin/ */}
              {/* catch all  */}
              <Route path={`*`} element={<Missing />} />
            </Routes>
          </IntlProvider>
        </div>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;
