// react
import * as React from "react";
import { motion } from "framer-motion";
import { TndevCtx } from "../../contexts/TndevContext";
import Cookies from "js-cookie";
import {
  Link,
  Outlet,
  useNavigate,
  useLocation,
  Navigate,
  NavLink,
} from "react-router-dom";
import { useMutation } from "react-query";

// mui
import HomeIcon from "@mui/icons-material/Home";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import BallotIcon from "@mui/icons-material/Ballot";
import TextsmsIcon from "@mui/icons-material/Textsms";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
//import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import Paper from "@mui/material/Paper";
import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Breadcrumbs,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// ch component

import Navbar from "../../components/Navbar";
import cnss from "../../assets/logo/idarti-logo.png";
import FooterDashboard from "../../components/FooterDashboard";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Steg() {
  const [methods, states] = TndevCtx();
  const { authMethods } = methods;
  const { apiLogin } = authMethods;
  const { setLoguedIn, user, setUser, lang } = states;

  let navigate = useNavigate();
  let location = useLocation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Navbar />
      <Grid container spacing={0} sx={{ mt: 10, minHeight: "100vh" }}>
        <Grid className="sidebar" item xs={2}>
          <Item sx={{ minHeight: "100vh" }}>
            <Breadcrumbs sx={{ ml: 2 }}>
              <Link
                underline="hover"
                color="inherit"
                to="/portal"
                style={{ textDecoration: "none", color: "#333" }}
              >
                البوابة
              </Link>
              <Link
                underline="hover"
                color="inherit"
                to="/material-ui/getting-started/installation/"
                style={{ textDecoration: "none", color: "#333" }}
              >
                الادارة
              </Link>
            </Breadcrumbs>
            <Box>
              <List>
                <ListItem>
                  <ListItemIcon>
                    {" "}
                    <img src={cnss} alt="steg" height="53" />
                  </ListItemIcon>
                  <Typography
                    component="h2"
                    variant="h6"
                    color="primary"
                    gutterBottom
                    align={lang === "ar" ? "left" : "right"}
                  >
                    الادارة
                  </Typography>
                </ListItem>
              </List>

              <Divider />

              <List>
                <ListItem sx={{ mb: -1, cursor: "pointer" }}>
                  <ListItemIcon>
                    <MoveDownIcon />
                  </ListItemIcon>
                  <NavLink
                    to="admin1"
                    style={{ textDecoration: "none" }}
                    className={({ isActive }) => (isActive ? "red" : "gray")}
                  >
                    <motion.span
                      style={{ display: "block" }}
                      whileHover={{
                        x: 5,
                        scale: 1.02,
                        // color: "red",
                        // transition: { ease: "easeOut", duration: 0.71 },
                      }}
                    >
                      إدارة المستخدم
                    </motion.span>
                  </NavLink>
                </ListItem>
                <ListItem sx={{ mb: -1, cursor: "pointer" }}>
                  <ListItemIcon>{/* <FeaturedPlayListIcon /> */}</ListItemIcon>
                  <NavLink
                    to="admin2"
                    style={{ textDecoration: "none" }}
                    className={({ isActive }) => (isActive ? "red" : "gray")}
                  >
                    <motion.span
                      style={{ display: "block" }}
                      whileHover={{
                        x: 5,
                        scale: 1.02,
                        // color: "red",
                        // transition: { ease: "easeOut", duration: 0.71 },
                      }}
                    >
                      {/* Admin Link2 */}
                    </motion.span>
                  </NavLink>
                </ListItem>
                <ListItem sx={{ mb: -1, cursor: "pointer" }}>
                  <ListItemIcon>{/* <BallotIcon /> */}</ListItemIcon>
                  <NavLink
                    to="admin3"
                    style={{ textDecoration: "none" }}
                    className={({ isActive }) => (isActive ? "red" : "gray")}
                  >
                    <motion.span
                      style={{ display: "block" }}
                      whileHover={{
                        x: 5,
                        scale: 1.02,
                        // color: "red",
                        // transition: { ease: "easeOut", duration: 0.71 },
                      }}
                    >
                      {/* Admin Link3 */}
                    </motion.span>
                  </NavLink>
                </ListItem>
                <ListItem sx={{ mb: -1, cursor: "pointer" }}>
                  <ListItemIcon>{/* <CalendarMonthIcon /> */}</ListItemIcon>
                  <NavLink
                    to="admin4"
                    style={{ textDecoration: "none" }}
                    className={({ isActive }) => (isActive ? "red" : "gray")}
                  >
                    <motion.span
                      style={{ display: "block" }}
                      whileHover={{
                        x: 5,
                        scale: 1.02,
                        // color: "red",
                        // transition: { ease: "easeOut", duration: 0.71 },
                      }}
                    >
                      {/* Admin Link4 */}
                    </motion.span>
                  </NavLink>
                </ListItem>

                <ListItem sx={{ mb: -1, cursor: "pointer" }}>
                  <ListItemIcon>{/* <InboxIcon /> */}</ListItemIcon>
                  <NavLink
                    to="admin5"
                    style={{ textDecoration: "none" }}
                    className={({ isActive }) => (isActive ? "red" : "gray")}
                  >
                    <motion.span
                      style={{ display: "block" }}
                      whileHover={{
                        x: 5,
                        scale: 1.02,
                        // color: "red",
                        // transition: { ease: "easeOut", duration: 0.71 },
                      }}
                    >
                      {/* Admin Link5 */}
                    </motion.span>
                  </NavLink>
                </ListItem>
                <ListItem sx={{ mb: -1, cursor: "pointer" }}>
                  <ListItemIcon>{/* <TextsmsIcon /> */}</ListItemIcon>
                  <NavLink
                    to="admin6"
                    style={{ textDecoration: "none" }}
                    className={({ isActive }) => (isActive ? "red" : "gray")}
                  >
                    <motion.span
                      style={{ display: "block" }}
                      whileHover={{
                        x: 5,
                        scale: 1.02,
                        // color: "red",
                        // transition: { ease: "easeOut", duration: 0.71 },
                      }}
                    >
                      {/* Admin Link6 */}
                    </motion.span>
                  </NavLink>
                </ListItem>
                <ListItem sx={{ mb: -1, cursor: "pointer" }}>
                  <ListItemIcon>{/* <AutoAwesomeMosaicIcon /> */}</ListItemIcon>
                  <NavLink
                    to="admin7"
                    style={{ textDecoration: "none" }}
                    className={({ isActive }) => (isActive ? "red" : "gray")}
                  >
                    <motion.span
                      style={{ display: "block" }}
                      whileHover={{
                        x: 5,
                        scale: 1.02,
                        // color: "red",
                        // transition: { ease: "easeOut", duration: 0.71 },
                      }}
                    >
                      {/* Admin Link7 */}
                    </motion.span>
                  </NavLink>
                </ListItem>
                <ListItem sx={{ mb: -1, cursor: "pointer" }}>
                  <ListItemIcon>{/* <AutoAwesomeMotionIcon /> */}</ListItemIcon>
                  <NavLink
                    to="admin8"
                    style={{ textDecoration: "none" }}
                    className={({ isActive }) => (isActive ? "red" : "gray")}
                  >
                    <motion.span
                      style={{ display: "block" }}
                      whileHover={{
                        x: 5,
                        scale: 1.02,
                        // color: "red",
                        // transition: { ease: "easeOut", duration: 0.71 },
                      }}
                    >
                      {/* Admin Link8 */}
                    </motion.span>
                  </NavLink>
                </ListItem>
              </List>
            </Box>
          </Item>
        </Grid>
        <Grid className="content" item xs={10}>
          <Item sx={{ minHeight: "100vh" }}>
            {/* components steg here */}

            <Outlet />
          </Item>
          <FooterDashboard />
        </Grid>
      </Grid>
    </>
  );
}
