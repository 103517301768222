// react
import React, { useState } from "react";
import { motion } from "framer-motion";
import { TndevCtx } from "../../contexts/TndevContext";
import Cookies from "js-cookie";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Link,
  Outlet,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import { useMutation } from "react-query";

// mui

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Divider from "@mui/material/Divider";

import TreeView from "@mui/lab/TreeView";

import TreeItem from "@mui/lab/TreeItem";
import Paper from "@mui/material/Paper";

import { styled } from "@mui/material/styles";
import { Card, Container } from "@mui/material";
import ApexCharts from "apexcharts";
import Chart from "react-apexcharts";
// ch component

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Stat1() {
  const [methods, states] = TndevCtx();
  const { authMethods } = methods;
  const { apiLogin } = authMethods;
  const { setLoguedIn, user, setUser, lang } = states;

  let navigate = useNavigate();
  let location = useLocation();

  const {
    mutate: login,
    isError,
    isLoading,
    isSuccess,
    data,
  } = useMutation((values) => apiLogin(values), {
    onSuccess: (data) => {
      const { access_token, user } = data;
      if (access_token) {
        setLoguedIn(true);
        setUser(user);
        Cookies.set("user", JSON.stringify(user));
        Cookies.set("token3s", access_token);
        navigate(`/calendrier`);
      }
    },
    onError: (error) => console.log(error),
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let cred = {
      email: data.get("email"),
      password: data.get("password"),
    };

    login(cred);
  };

  const [valueTab, setValueTab] = React.useState("operation");

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };
  const handleChangeOperation = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleNavigate = (event, newRoute) => {
    alert(newRoute);
    navigate(newRoute);
  };

  const [dataApex, setDataApex] = useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: ["بصدد الانجاز", "تمت معالجته", "في الانتضار"],
      },
    },
    series: [
      {
        name: "volume incidents",
        data: [10, 40, 15],
      },
    ],
  });
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [value, setValue] = React.useState(new Date());

  return (
    <>
      {" "}
      <Box>
        <Typography
          component="h2"
          variant="h6"
          color="primary"
          gutterBottom
          align={lang === "ar" ? "left" : "right"}
        >
          {/* احصاء */}
        </Typography>

        <Box sx={{ width: "100%" }}>
          <Tabs
            value={valueTab}
            onChange={handleChangeTab}
            textColor="primary"
            indicatorColor="primary"
            aria-label="primary tabs example"
          >
            <Tab value="operation" label="رؤية عامة" />
            {/* <Tab value="descriptif" label="Descriptif" /> */}
            {/* <Tab value="documentsNecessaires" label="Documents necessaires" /> */}
          </Tabs>
          <Divider />

          {valueTab === "operation" && (
            <Box sx={{ flexGrow: 1, mt: 4 }} component="form">
              <Box
                sx={{ pr: lang === "ar" ? 9 : 3, pl: lang === "ar" ? 3 : 9 }}
              >
                <Box sx={{ bgcolor: "transparent", my: 6, p: 1 }}>
                  {" "}
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <FormControl
                        variant="standard"
                        sx={{ m: 1, minWidth: "100%" }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          الشريك
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={age}
                          onChange={handleChange}
                          label="Age"
                        >
                          <MenuItem value="">
                            <em>لا شئ</em>
                          </MenuItem>
                          <MenuItem value={10}>
                            {" "}
                            الشركة التونسية للكهرباء والغاز{" "}
                          </MenuItem>
                          <MenuItem value={20}>
                            {" "}
                            صندوق الضمان الاجتماعي{" "}
                          </MenuItem>
                          <MenuItem value={30}>
                            {" "}
                            الشركة الوطنية لاستغلال و توزيع المياه
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={3}>
                      <FormControl
                        variant="standard"
                        sx={{ m: 1, minWidth: "100%" }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          الخدمات
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={age}
                          onChange={handleChange}
                          label="Age"
                        >
                          <MenuItem value="">
                            <em>لا شئ</em>
                          </MenuItem>
                          <MenuItem value={10}> تحويل عداد</MenuItem>
                          <MenuItem value={20}> كشف الفواتير</MenuItem>
                          <MenuItem value={30}> كشف الدفوعات</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          renderInput={(props) => <TextField {...props} />}
                          label="بداية الفترة"
                          value={value}
                          onChange={(newValue) => {
                            setValue(newValue);
                          }}
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          renderInput={(props) => <TextField {...props} />}
                          label="نهاية الفترة"
                          value={value}
                          onChange={(newValue) => {
                            setValue(newValue);
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Card sx={{ bgcolor: "tomato", padding: 3 }}>
                      <Typography variant="h5" sx={{ color: "white" }}>
                        في الانتضار
                      </Typography>
                      <Typography variant="h6" sx={{ mt: 1, color: "white" }}>
                        10
                      </Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card sx={{ bgcolor: "orange", padding: 3 }}>
                      <Typography variant="h5" sx={{ color: "white" }}>
                        بصدد الانجاز
                      </Typography>
                      <Typography variant="h6" sx={{ mt: 1, color: "white" }}>
                        300
                      </Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card sx={{ bgcolor: "green", padding: 3 }}>
                      <Typography variant="h5" sx={{ color: "white" }}>
                        تمت
                      </Typography>
                      <Typography variant="h6" sx={{ mt: 1, color: "white" }}>
                        3500
                      </Typography>
                    </Card>
                  </Grid>
                </Grid>

                <Grid container spacing={1} sx={{ mt: 4 }}>
                  <Grid item xs={4}>
                    <Typography>
                      <Box sx={{ display: "flex" }}>
                        <div className="row">
                          <div className="mixed-chart">
                            <Chart
                              options={dataApex.options}
                              series={dataApex.series}
                              type="bar"
                              width="500"
                            />
                          </div>
                          <Typography
                            sx={{ color: "#333" }}
                            align="center"
                            variant="h6"
                          >
                            {" "}
                            النتائج الاولية{" "}
                          </Typography>
                        </div>
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      <Box sx={{ display: "flex" }}>
                        <div className="row">
                          <div className="mixed-chart">
                            <Chart
                              options={dataApex.options}
                              series={dataApex.series}
                              type="bar"
                              width="500"
                            />
                          </div>
                          <Typography
                            sx={{ color: "#333" }}
                            align="center"
                            variant="h6"
                          >
                            {" "}
                            النتائج الاولية{" "}
                          </Typography>
                        </div>
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      <Box sx={{ display: "flex" }}>
                        <div className="row">
                          <div className="mixed-chart">
                            <Chart
                              options={dataApex.options}
                              series={dataApex.series}
                              type="bar"
                              width="500"
                            />
                          </div>
                          <Typography
                            sx={{ color: "#333" }}
                            align="center"
                            variant="h6"
                          >
                            {" "}
                            النتائج الاولية{" "}
                          </Typography>
                        </div>
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
          {valueTab === "descriptif" && (
            <Box sx={{ textAlign: "left", p: 5 }}>
              <p>
                {" "}
                طباعة البريد الإلكتروني Lorem ipsum للمصممين نص لوريم ايبسوم
                عربي المجموعة: مقالات دعاية الزيارات: 218700 Lorem ipsum
                للمصممين نص لوريم ايبسوم بالعربي عربي انجليزي ما هو لوريم ايبسوم
                Lorem ipsum لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض
                على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم
                مطبوعه ... بروشور او فلاير على سبيل المثال ... او نماذج مواقع
                انترنت ... وعند موافقه العميل المبدئيه على التصميم يتم ازالة هذا
                النص من التصميم ويتم وضع النصوص النهائية المطلوبة للتصميم ويقول
                البعض ان وضع النصوص التجريبية بالتصميم قد تشغل المشاهد عن وضع
                الكثير من الملاحظات او الانتقادات للتصميم الاساسي. وخلافاَ
                للاعتقاد السائد فإن لوريم إيبسوم ليس نصاَ عشوائياً، بل إن له
                جذور في الأدب اللاتيني الكلاسيكي منذ العام 45 قبل الميلاد. من
                كتاب "حول أقاصي الخير والشر"
              </p>
            </Box>
          )}
          {valueTab === "documentsNecessaires" && (
            <Box sx={{ textAlign: "left", p: 5 }}>
              {" "}
              <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{
                  height: 240,
                  flexGrow: 1,
                  maxWidth: 400,
                  overflowY: "auto",
                }}
              >
                <TreeItem nodeId="1" label="STEG">
                  <TreeItem
                    nodeId="32"
                    label="Calendar"
                    icon={<LocalPrintshopIcon />}
                  />{" "}
                </TreeItem>
                <TreeItem nodeId="2" label="STEG-2">
                  <TreeItem
                    nodeId="33"
                    label="myCalendar"
                    icon={<LocalPrintshopIcon />}
                  />{" "}
                </TreeItem>
              </TreeView>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export default Stat1;
