import { Container, Grid, IconButton, Paper, Typography } from "@mui/material";
import React from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import steg from "../../assets/msa/steg.png";
import cnss from "../../assets/msa/cnss.png";
import sonede from "../../assets/msa/sonede.png";
import finance from "../../assets/msa/finance.jpg";

function Home() {
  return (
    <>
      <Navbar />

      <Container
        maxWidth="xl"
        sx={{ mt: 10, minHeight: "90vh", bgcolor: "transparent" }}
      >
        <Paper sx={{ width: "100%", padding: 2 }} elevation={2}>
          {" "}
          <Typography variant="h5"> اهلا فولان بن فولان </Typography>{" "}
        </Paper>
        <Paper sx={{ width: "100%", padding: 1, my: 3 }} elevation={2}>
          {" "}
          <Grid container spacing={2}>
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <img
                src={steg}
                alt="idarti"
                height="45"
                className="ml-2"
                style={{
                  marginRight: "0.5rem",
                  display: "Block",
                  maxWidth: "100%",
                }}
              />{" "}
              <Typography variant="h5">
                الشركة التونسية للكهرباء والغاز{" "}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Typography variant="h5"> في الانتظار </Typography>{" "}
              <Typography
                variant="h5"
                sx={{
                  mt: 1,
                  color: "gray",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                144
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h5"> تمت </Typography>{" "}
              <Typography
                variant="h5"
                sx={{
                  mt: 1,
                  color: "green",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                356
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h5"> بصدد المعالجة </Typography>{" "}
              <Typography
                variant="h5"
                sx={{
                  mt: 1,
                  color: "orange",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                8
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ width: "100%", padding: 1, my: 3 }} elevation={2}>
          {" "}
          <Grid container spacing={3}>
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <img
                src={cnss}
                alt="idarti"
                height="45"
                className="ml-2"
                style={{
                  marginRight: "0.5rem",
                  display: "Block",
                  maxWidth: "100%",
                }}
              />{" "}
              <Typography variant="h5"> صندوق الضمان الاجتماعي </Typography>{" "}
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Typography variant="h5"> في الانتظار </Typography>{" "}
              <Typography
                variant="h5"
                sx={{
                  mt: 1,
                  color: "gray",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                144
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h5"> تمت </Typography>{" "}
              <Typography
                variant="h5"
                sx={{
                  mt: 1,
                  color: "green",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                356
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h5"> بصدد المعالجة </Typography>{" "}
              <Typography
                variant="h5"
                sx={{
                  mt: 1,
                  color: "orange",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                8
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ width: "100%", padding: 1, my: 3 }} elevation={2}>
          {" "}
          <Grid container spacing={2}>
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <img
                src={sonede}
                alt="idarti"
                height="45"
                className="ml-2"
                style={{
                  marginRight: "0.5rem",
                  display: "Block",
                  maxWidth: "100%",
                }}
              />{" "}
              <Typography variant="h5">
                الشركة الوطنية لاستغلال و توزيع المياه
              </Typography>{" "}
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Typography variant="h5"> في الانتظار </Typography>{" "}
              <Typography
                variant="h5"
                sx={{
                  mt: 1,
                  color: "gray",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                144
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h5"> تمت </Typography>{" "}
              <Typography
                variant="h5"
                sx={{
                  mt: 1,
                  color: "green",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                356
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h5"> بصدد المعالجة </Typography>{" "}
              <Typography
                variant="h5"
                sx={{
                  mt: 1,
                  color: "orange",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                8
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ width: "100%", padding: 1, my: 3 }} elevation={2}>
          {" "}
          <Grid container spacing={3}>
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <img
                src={finance}
                alt="idarti"
                height="45"
                className="ml-2"
                style={{
                  marginRight: "0.5rem",
                  display: "Block",
                  maxWidth: "100%",
                }}
              />{" "}
              <Typography variant="h5">وزارة المالية</Typography>{" "}
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Typography variant="h5"> في الانتظار </Typography>{" "}
              <Typography
                variant="h5"
                sx={{
                  mt: 1,
                  color: "gray",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                144
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h5"> تمت </Typography>{" "}
              <Typography
                variant="h5"
                sx={{
                  mt: 1,
                  color: "green",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                356
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h5"> بصدد المعالجة </Typography>{" "}
              <Typography
                variant="h5"
                sx={{
                  mt: 1,
                  color: "orange",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                8
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <Footer />
    </>
  );
}

export default Home;
