// react
import * as React from "react";
import { motion } from "framer-motion";
import { TndevCtx } from "../../../contexts/TndevContext";
import Cookies from "js-cookie";
import {
  Link,
  Outlet,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";

// mui
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import TablePagination from "@mui/material/TablePagination";
import { red, orange, blue, blueGrey, green } from "@mui/material/colors";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Divider from "@mui/material/Divider";

import TreeView from "@mui/lab/TreeView";

import TreeItem from "@mui/lab/TreeItem";
import Paper from "@mui/material/Paper";

import { styled } from "@mui/material/styles";
import PrintIcon from "@mui/icons-material/Print";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import InputBase from "@mui/material/InputBase";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import SearchIcon from "@mui/icons-material/Search";
import { Button, IconButton } from "@mui/material";
import daysToWeeks from "date-fns/esm/daysToWeeks";
// ch component

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: blueGrey[400],
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function ReleveCarriere() {
  const queryClient = useQueryClient();
  const [methods, states] = TndevCtx();
  const { cnssApiMethods, authMethods } = methods;
  const { apiLogin } = authMethods;
  const { apiReleveCarriereGetAll, apiReleveCarriereGetOne } = cnssApiMethods;
  const { bigData, setBigData, setLoguedIn, user, setUser, lang } = states;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let navigate = useNavigate();
  let location = useLocation();

  const [valueTab, setValueTab] = React.useState("operation");

  // react query lives here

  // const {
  //   isSuccess: releveSuccess,
  //   isLoading: releveLoading,
  //   refetch,
  //   error: releveError,
  //   data: releveData,
  //   isFetching,
  // } = useQuery(["releve-carriere-by-matricule"], () => apiReleveCarriereGetOne(), {
  //   onSuccess: (data) => {
  //     setBigData(data);
  //   },
  //   onError: (error) => console.log(error),
  // });

  function ReleveCarriereGetOne({ matricule }) {
    const result = useQuery(
      ["ReleveCarriereGetOne", matricule],
      () => apiReleveCarriereGetOne(matricule),
      {
        onSuccess: (data) => {
          setBigData(data);
        },
        onError: (error) => console.log(error),
      }
    );
  }

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };
  const handleChangeOperation = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleNavigate = (event, newRoute) => {
    alert(newRoute);
    navigate(newRoute);
  };

  const handleSubmitSearch = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    let cred = {
      matricule: data.get("matricule"),
      cle: data.get("cle"),
    };
    const dataOne = apiReleveCarriereGetOne(cred.matricule)
      .then((res) => {
        console.log(res);
        setBigData(res);
      })
      .catch((err) => console.log(err));
  };

  const [age, setAge] = React.useState("");

  const handleChangeAge = (event) => {
    setAge(event.target.value);
  };

  return (
    <>
      <Box>
        <Typography
          component="h2"
          variant="h6"
          color="primary"
          gutterBottom
          align={lang === "ar" ? "left" : "right"}
        >
          كشف عن الحياة المهنية
        </Typography>

        <Box sx={{ width: "100%" }}>
          <Tabs
            value={valueTab}
            onChange={handleChangeTab}
            textColor="primary"
            indicatorColor="primary"
            aria-label="primary tabs example"
          >
            <Tab value="operation" label="عملية" />
            <Tab value="descriptif" label="تفسير" />
            <Tab value="documentsNecessaires" label="وثائق هامة" />
          </Tabs>
          <Divider />

          {valueTab === "operation" && (
            <>
              <Box
                className="fomulaire de recherche par matricule "
                component="form"
                onSubmit={handleSubmitSearch}
                sx={{
                  float: "left",
                  my: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  type="submit"
                  sx={{ p: "20px" }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="مفتاح"
                  name="cle"
                  id="cle"
                  inputProps={{ "aria-label": "Cle" }}
                />
                <InputBase
                  autoFocus
                  sx={{ ml: -16, flex: 1 }}
                  placeholder="معرف"
                  name="matricule"
                  id="matricule"
                  inputProps={{ "aria-label": "Matricule" }}
                />
              </Box>

              {/* <Box
                className="filtrage par annee trimestre  "
                component="form"
                onSubmit={handleSubmitSearch}
                sx={{
                  float: "left",
                  my: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  type="submit"
                  sx={{ p: "20px" }}
                  aria-label="search"
                >
                  <FilterAltIcon />
                </IconButton>
                <Box sx={{ minWidth: 120 }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Age"
                    onChange={handleChangeAge}
                    sx={{ minWidth: "3rem" }}
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Age"
                    onChange={handleChangeAge}
                    sx={{ minWidth: "3rem", mx: 2 }}
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </Box>
              </Box> */}
              <Box
                className="print"
                component="form"
                onSubmit={handleSubmitSearch}
                sx={{
                  float: "left",
                  my: 2,
                }}
              >
                <IconButton
                  type="submit"
                  sx={{ p: "20px" }}
                  aria-label="search"
                >
                  <PrintIcon />
                </IconButton>
              </Box>

              {bigData && bigData ? (
                <Box sx={{ flexGrow: 1, mt: 1 }}>
                  <Box
                    sx={{
                      pr: lang === "ar" ? 9 : 3,
                      pl: lang === "ar" ? 3 : 9,
                    }}
                  >
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 700 }}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            {/* <StyledTableCell align="right">
                              العملية
                            </StyledTableCell> */}
                            <StyledTableCell align="right">
                              الثلاثية
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              السنة
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              الاجر
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              معرف
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {bigData &&
                            bigData
                              ?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((item) => (
                                <StyledTableRow key={item.rownum}>
                                  {/* <StyledTableCell align="right">
                                    <VisibilityIcon />
                                  </StyledTableCell> */}
                                  <StyledTableCell
                                    align="right"
                                    className="trimestre"
                                  >
                                    {item.trimestre}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="right"
                                    className="annee"
                                  >
                                    {item.annee}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="right"
                                    className="salaire"
                                  >
                                    {item.salaire}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="right"
                                    className="matricule"
                                  >
                                    {item.matricule}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      labelRowsPerPage=""
                      rowsPerPageOptions={[5, 20, 50]}
                      component="div"
                      count={bigData?.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Box>
                </Box>
              ) : (
                <h4 style={{ color: "red", marginTop: "3rem" }}>
                  المعرف غير موجود{" "}
                </h4>
              )}
            </>
          )}
          {valueTab === "descriptif" && (
            <Box sx={{ textAlign: "left", p: 5 }}>
              <p>
                {" "}
                طباعة البريد الإلكتروني Lorem ipsum للمصممين نص لوريم ايبسوم
                عربي المجموعة: مقالات دعاية الزيارات: 218700 Lorem ipsum
                للمصممين نص لوريم ايبسوم بالعربي عربي انجليزي ما هو لوريم ايبسوم
                Lorem ipsum لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض
                على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم
                مطبوعه ... بروشور او فلاير على سبيل المثال ... او نماذج مواقع
                انترنت ... وعند موافقه العميل المبدئيه على التصميم يتم ازالة هذا
                النص من التصميم ويتم وضع النصوص النهائية المطلوبة للتصميم ويقول
                البعض ان وضع النصوص التجريبية بالتصميم قد تشغل المشاهد عن وضع
                الكثير من الملاحظات او الانتقادات للتصميم الاساسي. وخلافاَ
                للاعتقاد السائد فإن لوريم إيبسوم ليس نصاَ عشوائياً، بل إن له
                جذور في الأدب اللاتيني الكلاسيكي منذ العام 45 قبل الميلاد. من
                كتاب "حول أقاصي الخير والشر"
              </p>
            </Box>
          )}
          {valueTab === "documentsNecessaires" && (
            <Box sx={{ textAlign: "left", p: 5 }}>
              {" "}
              <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{
                  height: 240,
                  flexGrow: 1,
                  maxWidth: 400,
                  overflowY: "auto",
                }}
              >
                <TreeItem nodeId="1" label="STEG">
                  <TreeItem
                    nodeId="32"
                    label="Calendar"
                    icon={<LocalPrintshopIcon />}
                  />{" "}
                </TreeItem>
                <TreeItem nodeId="2" label="STEG-2">
                  <TreeItem
                    nodeId="33"
                    label="myCalendar"
                    icon={<LocalPrintshopIcon />}
                  />{" "}
                </TreeItem>
              </TreeView>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export default ReleveCarriere;
