// react
import * as React from "react";
import { TndevCtx } from "../contexts/TndevContext";
import Cookies from "js-cookie";
import { motion } from "framer-motion";
import {
  Link,
  useNavigate,
  NavLink,
  useParams,
  Navigate,
  useLocation,
} from "react-router-dom";

import Fab from "@mui/material/Fab";
import ChatIcon from "@mui/icons-material/Chat";

// i18n
import { FormattedMessage, useIntl } from "react-intl";

// mui
import CircularProgress from "@mui/material/CircularProgress";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MoreIcon from "@mui/icons-material/MoreVert";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import LanguageIcon from "@mui/icons-material/Language";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";

// components

import logo from "../assets/logo/tunisia-coat.png";
import idarti from "../assets/logo/idarti.png";
import Drawers from "./Drawers";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function Navbar() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [methods, states] = TndevCtx();
  const { authMethods } = methods;
  const { apiLogin } = authMethods;
  const {
    loguedIn,
    setLoguedIn,
    user,
    setUser,
    auth,
    setAuth,
    loading,
    setLoading,
    lang,
    setLang,
  } = states;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const [anchorElProfile, setAnchorElProfile] = React.useState(null);
  const [anchorElNotification, setAnchorElNotification] = React.useState(null);
  const [anchorElLanguage, setAnchorElLanguage] = React.useState(null);
  const openProfile = Boolean(anchorElProfile);
  const openNotification = Boolean(anchorElNotification);
  const openLanguage = Boolean(anchorElLanguage);

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickProfile = (event) => {
    setAnchorElProfile(event.currentTarget);
  };
  const handleClickNotification = (event) => {
    setAnchorElNotification(event.currentTarget);
  };
  const handleClickLanguage = (event) => {
    setAnchorElLanguage(event.currentTarget);
  };
  const handleAccount = () => {
    navigate("/account");
    setAnchorElProfile(null);
  };
  const handleCloseProfile = () => {
    setAnchorElProfile(null);
  };
  const handleCloseNotification = () => {
    setAnchorElNotification(null);
  };
  const handleChangeLanguage = (lang) => {
    setLang(lang);
    setAnchorElLanguage(null);
  };
  const handleCloseLanguage = () => {
    setAnchorElLanguage(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleLogout = () => {
    // if (Cookies.get("token3s")) {
    //   Cookies.remove("token3s");
    //   Cookies.remove("user");
    // }

    // setLoguedIn(false);
    // setAnchorEl(null);
    // handleMobileMenuClose();
    setAuth(false);
    setLang("ar");
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const menuId = "primary-search-account-menu";

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {!loguedIn && <MenuItem onClick={handleMenuClose}>Connexion</MenuItem>}

      {loguedIn && <MenuItem onClick={handleLogout}>Deconnexion</MenuItem>}
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Connexion</MenuItem>
      <MenuItem onClick={handleLogout}>Deconnexion</MenuItem>
    </Menu>
  );

  const preventDefault = (event) => event.preventDefault();

  const [state, setState] = React.useState({
    left: false,
    top: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  React.useEffect(() => {
    setLoading(false);
  }, []);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Fab
        color="secondary"
        aria-label="edit"
        sx={{ position: "fixed", bottom: "2rem", left: "2rem" }}
      >
        <ChatIcon />
      </Fab>
      <Drawers state={state} anchor={state.left} toggleDrawer={toggleDrawer} />
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#fff",
          color: "#333",
        }}
      >
        <Toolbar sx={{ bgcolor: "transparent" }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 1, display: { xs: "block", md: "none" } }}
            onClick={toggleDrawer(Object.keys(state)[0], true)}
          >
            <MenuIcon />
          </IconButton>
          <img
            src={logo}
            alt="president"
            height="45"
            className="ml-2"
            style={{
              marginRight: "0.5rem",
              display: "Block",
              maxWidth: "100%",
            }}
          />
          <img
            src={idarti}
            alt="idarti"
            height="45"
            className="ml-2"
            style={{
              marginRight: "0.5rem",
              display: "Block",
              maxWidth: "100%",
            }}
          />
          {/* <Box sx={{ flexGrow: 1 }} /> */}
          <Box sx={{ flexGrow: 1, width: "100%" }}>
            <Box
              sx={{
                width: "35%",
                display: "flex",
                margin: "auto",
                flexWrap: "wrap",
                justifyContent: "center ",
                typography: "body",

                "& > :not(style) + :not(style)": {
                  ml: 2,
                },
                display: { xs: "none", md: "block" },
              }}
              onClick={preventDefault}
            >
              <NavLink
                className={({ isActive }) => (isActive ? "red" : "gray")}
                style={{
                  textDecoration: "none",
                  marginRight: "10px",
                }}
                to="/home"
              >
                <FormattedMessage id="home" />
              </NavLink>

              <NavLink
                className={({ isActive }) => (isActive ? "red" : "gray")}
                style={{
                  textDecoration: "none",
                  marginRight: "10px",
                }}
                to="/portail"
              >
                <FormattedMessage id="portal" />
              </NavLink>

              <NavLink
                className={({ isActive }) => (isActive ? "red" : "gray")}
                style={{ textDecoration: "none" }}
                to="/administration"
              >
                <FormattedMessage id="administration" />
              </NavLink>

              <NavLink
                className={({ isActive }) => (isActive ? "red" : "gray")}
                style={{ textDecoration: "none" }}
                to="/discussion"
              >
                <FormattedMessage id="discussion" />
              </NavLink>

              <NavLink
                className={({ isActive }) => (isActive ? "red" : "gray")}
                style={{ textDecoration: "none" }}
                to="/statistics"
              >
                <FormattedMessage id="statistic" />
              </NavLink>
              <NavLink
                className={({ isActive }) => (isActive ? "red" : "gray")}
                style={{ textDecoration: "none" }}
                to="/faq"
              >
                {/* <FormattedMessage id="FAQ" /> */}
                اسئلة متادولة
              </NavLink>
            </Box>
          </Box>{" "}
          {/* end menu  */}
          <div>
            <Typography
              noWrap
              sx={{ cursor: "pointer", display: "flex" }}
              onClick={handleClickProfile}
              onMouseEnter={handleClickProfile}
            >
              {/* <FormattedMessage id="hello" />{" "} */}
              {/* <span>{loguedIn && `${user.fname} ${user.lname}`} </span> */}
              <span> فولان بن فولان</span>
              <ArrowDropDownIcon />
            </Typography>{" "}
            <Menu
              id="basic-menu"
              anchorEl={anchorElProfile}
              open={openProfile}
              onClose={handleCloseProfile}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleAccount}>حساب</MenuItem>
              <MenuItem onClick={handleLogout}>خروج</MenuItem>
            </Menu>
          </div>
          <div>
            <Badge
              badgeContent={4}
              color="primary"
              sx={{ mt: -1, mr: 1.5, ml: 1, cursor: "pointer" }}
              onClick={handleClickNotification}
              onMouseEnter={handleClickNotification}
            >
              <NotificationsNoneIcon color="action" />
            </Badge>
            <Menu
              id="basic-menu"
              anchorEl={anchorElNotification}
              open={openNotification}
              onClose={handleCloseNotification}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleCloseNotification}>معلومة 1</MenuItem>
              <MenuItem onClick={handleCloseNotification}>معلومة 2</MenuItem>
            </Menu>
          </div>
          {/* <Box>
            <Typography
              noWrap
              sx={{
                mx: 0.5,
                cursor: "pointer",
                display: "flex",
                textTransform: "uppercase",
              }}
              onClick={handleClickLanguage}
              onMouseEnter={handleClickLanguage}
            >
              {lang == "ar" ? "🇹🇳" : lang == "fr" ? "🇨🇵" : "🇬🇧"}
              <ArrowDropDownIcon />
            </Typography>
            <Menu
              id="basic-menu"
              anchorEl={anchorElLanguage}
              open={openLanguage}
              onClose={handleCloseLanguage}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={() => handleChangeLanguage("fr")}>🇨🇵</MenuItem>
              <MenuItem onClick={() => handleChangeLanguage("ar")}>🇹🇳</MenuItem>
              <MenuItem onClick={() => handleChangeLanguage("EN")}>🇬🇧</MenuItem>
            </Menu>
          </Box> */}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
