import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import { motion } from "framer-motion";

const Layout = () => {
  return (
    <motion.div
      className="App"
      key="layout"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.7 }}
    >
      {/* navbar here  */}

      <Outlet />
      {/* footer here  */}
    </motion.div>
  );
};

export default Layout;
