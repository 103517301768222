import { Box, Typography } from "@mui/material";
import React from "react";

function FooterDashboard() {
  return (
    <Box
      sx={{
        mt: 3,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography sx={{ color: "#000", align: "center" }}>
        جميع الحقوق محفوظة &copy; {new Date().getFullYear()} إدارتي Tac Tic
      </Typography>
    </Box>
  );
}

export default FooterDashboard;
